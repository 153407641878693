import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {NavLink, useLocation} from 'react-router-dom';
import {typeApp, typeCopy} from 'utils/constants';

const MenuItems = (item, index, activeIndex, closeMenu, selectMenuItem) => {
  if (navigator.userAgent === 'ReactSnap') {
    return;
  }

  const isActive = index === activeIndex;

  const scrollTo = () => {
    selectMenuItem(index);
    if (window.innerWidth <= 1140) {
      closeMenu();
    }
  };

  return (
    <li key={index} className={isActive ? 'header-active ' : null}>
      {item.out === true ? (
        <a href={item.link} rel="noopener noreferrer" target="_blank">
          {item.title}
        </a>
      ) : (
        <NavLink onClick={scrollTo} to={item.link}>
          {item.title}
        </NavLink>
      )}
    </li>
  );
};

function Navigation({closeMenu}) {
  if (!process) return null;

  const type = process.env.REACT_APP_PROJECT_TYPE;
  const location = useLocation();
  let data = [];
  const items = ['home', 'boats', 'corporate', 'faq'];
  const isRentABoat = window.location.pathname.includes('/rent-a-boat');
  const isHome = window.location.pathname === '/';
  const [isLoad, setIsLoad] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  if (type === typeApp) {
    data = [
      {
        link: '/rent-a-boat',
        title: 'Rent a boat',
      },
      {
        link: '/rent-a-yacht',
        title: 'Rent a yacht',
        page: true,
      },
      {
        link: '/boatpass',
        title: 'Boat pass',
        page: true,
      },
      {
        link: '/rent-a-boat#boats',
        title: 'Boats',
      },
      {
        link: '/rent-a-boat#corporate',
        title: 'Corporate',
      },
      {
        link: '/rent-a-boat#faq',
        title: 'Policies & Faq',
      },
      {
        link: '/blog/',
        title: 'Blog',
        out: true,
      },
    ];
  } else if (type === typeCopy) {
    data = [
      {
        link: '/rent-a-boat',
        title: 'Rent a boat',
      },
      {
        link: '/boatpass',
        title: 'Boat pass',
        page: true,
      },
      {
        link: '/rent-a-boat#boats',
        title: 'Boats',
      },
      {
        link: '/rent-a-boat#corporate',
        title: 'Corporate',
      },
      {
        link: '/rent-a-boat#faq',
        title: 'Policies & Faq',
      },
      {
        link: '/blog/',
        title: 'Blog',
        out: true,
      },
    ];
  }

  const selectMenuItem = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    if (location.hash === '') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      setTimeout(() => {
        const id = location.hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 100);
    }
  }, [location.pathname, location.hash, location.key]);
  useEffect(() => {
    const path = location.pathname;
    const hash = location.hash;
    const index = data.findIndex((item) => item.link === `${path}${hash}`);
    setActiveIndex(index);
  }, []);
  useEffect(() => {
    const onDomLoaded = () => {
      setIsLoad(true);
      window.removeEventListener('load', onDomLoaded);
    };
    window.addEventListener('load', onDomLoaded);
    return () => {
      window.removeEventListener('load', onDomLoaded);
    };
  });
  useEffect(() => {
    if (!isLoad) {
      return;
    }
    if (navigator.userAgent === 'ReactSnap') {
      return;
    }
    if (isHome) {
      setActiveIndex(null);
      return;
    }
    if (isRentABoat === false) {
      return;
    }
    const elements = items.map((el) => document.getElementById(el)).filter((el) => el !== null);
    if (elements.length === 0) {
      return;
    }

    const onScroll = () => {
      const elementsPos = elements.map((element) => ({
        id: element.id,
        top: element.getBoundingClientRect().top,
      }));
      const suitableElements = elementsPos.filter((element) => element.top + 200 > 0 && element.top < window.innerHeight);
      if (suitableElements[0]?.id === 'home' || suitableElements.length === 0) {
        setActiveIndex(0);
        return;
      }
      const index = data.findIndex((item) => item.link === `/rent-a-boat#${suitableElements[0]?.id}`);
      setActiveIndex(index);
    };

    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  return (
    <ul>
      {data.map((item, index) => MenuItems(item, index, activeIndex, closeMenu, selectMenuItem))}
      {navigator.userAgent === 'ReactSnap' ? null : (
        <li>
          <a href="https://waiver.smartwaiver.com/w/5ed02912ea933/web/" target="_blank" rel="noreferrer">
            <div className="signdigitalwaiver_button_div">Waiver</div>
          </a>
        </li>
      )}
    </ul>
  );
}

Navigation.propTypes = {
  closeMenu: PropTypes.func,
};

export default Navigation;
