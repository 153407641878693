import React, {useContext} from 'react';
import Navigation from 'components/common/navigation';
import {MenuContext} from 'react-flexible-sliding-menu';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import style from './menu.module.css';
import SignInButton from '../signInButton';

export default function Menu() {
  const {closeMenu} = useContext(MenuContext);
  return (
    <div className={style.menu}>
      <button type="button" className={style.close} onClick={closeMenu}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <Navigation closeMenu={closeMenu} />
      <SignInButton className={style.signInButton} isLink={true} />
    </div>
  );
}
