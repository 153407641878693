import React, {useState, useEffect} from 'react';
import Navigation from 'components/common/navigation';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTwitter, faFacebookF, faInstagram} from '@fortawesome/free-brands-svg-icons';
import useWindowDimensions from 'utils/useWindowsDimensions';
import SignInButton from '../signInButton';
import Logo from '../logo';
import hamburger from './hamburger.svg';
import style from './header.module.scss';

const useScrollHandler = (val) => {
  const [scroll, setScroll] = useState(1);

  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY < val;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [scroll, setScroll, val]);

  return scroll;
};

const CTAButton = ({visible}) => (
  <button type="button" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})} className={visible ? 'cta' : 'ctaHidden'}>
    Book Now
  </button>
);

CTAButton.propTypes = {
  visible: PropTypes.bool,
};

function Header({toggleMenu}) {
  const scrollHeader = useScrollHandler(10);
  const {pathname} = useLocation();
  const isHome = pathname.includes('rent-a-boat');
  const isYachts = pathname.includes('rent-a-yacht');

  let headerClass = 'header headerOffset';

  if (navigator.userAgent !== 'ReactSnap') {
    headerClass = isHome || isYachts ? (scrollHeader ? 'header' : 'header headerOffset') : 'header headerOffset';
  }

  const {width} = useWindowDimensions();
  const isMobile = width <= 720;

  const viewportHeight = document.documentElement.clientHeight;
  const mainBlockVisible = useScrollHandler(isMobile ? viewportHeight + 200 : viewportHeight * 0.6);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <header className={headerClass}>
      <div className="header-wrapper">
        <Logo />
        <nav className="nav">
          <Navigation />
          <ul className="social">
            <li>
              <a href="https://www.facebook.com/ChicagoElectricBoatCompany" rel="noopener noreferrer" target="_blank">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/chicagoeboats" rel="noopener noreferrer" target="_blank">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/ChicagoEBoats" rel="noopener noreferrer" target="_blank">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </li>
          </ul>
          <SignInButton className={style.singInButton} />
          <button type="button" className="menuIcon" onClick={toggleMenu}>
            <img src={hamburger} alt="menu icon" />
          </button>
        </nav>
        <CTAButton visible={!mainBlockVisible && isHome} />
      </div>
    </header>
  );
}

Header.propTypes = {
  toggleMenu: PropTypes.func,
};

export default Header;
