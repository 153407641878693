import {useState} from 'react';

const AppContent = () => {
  const home = {
    boatTypes: {
      title: 'Choose Your Boat Type',
      list: [
        '<p>The Chicago Electric Boat Company offers a unique, memorable and environmentally friendly way to see the great city of Chicago. By renting boats that are comfortable and easy to drive, the Chicago Electric Boat Company gives even new boaters a chance to get behind the wheel and see the city from this exceptional point of view.</p><p>Founded by Ron Silvia and the late Greg Krueger, the Chicago Electric Boat Company has grown to over a dozen vessels in only 7 years. Since opening our doors in April 2012, we have had the pleasure of introducing over 100,000 guests to the beauty of Chicago and the thrill of captaining a boat on the River. We are touched that passengers have chosen our boats as the preferred venue for celebrations, events, meetings, marriage proposals and even weddings.</p>',
        '<p>Introducing the newest Chicago experience, the city’s first ever Cycleboat! This social, active adventure is the perfect way for friends, families, tourists, and locals to explore one of Chicago’s greatest summertime attractions. Reserve a seat on one of our BYOB rides or reserve the entire boat for a special occasion. The Private Cycleboat has been restricted to 8 passengers and rides are 90 minutes long. The Large Private Cruise has been restricted to 13 passengers. There are 8 cycling stations on each side of the boat, which means that only every passengers can be cycling at once.</p>',
        '<p>Introducing Chicago Retro Boats, an intimate river experience like no other! Originally built in the 1950’s and 1960’s, our sweet little Retro Boats have been fully reconditioned and fitted with eco-friendly electric motors, bringing them into the 21st century.</p><p>Located on the Chicago Riverwalk, you are perfectly positioned to take off and see the very best of the city. Take a spin on a Retro Boat and explore the Chicago River in classic style!</p>',
        '<p>Today, Pathfinder Cruises offers a sophisticated and chic way to see the historic city of Chicago.</p><p>Each cruise is equipped with a full bar offering cocktails from either our brunch or main cocktail list.</p><p>Guests are welcome to bring their own food onboard, but the experience is made even more luxurious when upgraded with a personal chef to enjoy a multi-course meal. The chef must be booked at least 10 days prior to the departure date.</p><p>Cruising onboard Pathfinder is great for micro weddings, rehearsal dinners, anniversaries, business outings, and more!</p>',
      ],
      cardButtonText: 'Book Now',
    },
    locations: {
      title: 'Select the Cruise Location',
    },
    bookByLocation: {
      title: 'Book by location',
    },
  };
  const rentAYacht = {
    cover: {
      title: 'Rent a Yacht on the Chicago River',
    },
    yachts: {
      title: '',
      description: '',
    },
    content: {
      description: '',
    },
    gallery: [],
  };
  const rentABoats = {
    cover: {
      title: 'Rent a Boat on the Chicago River',
    },
    prices: {
      title: '',
      image: '',
      description: '',
    },
    cruise: {
      title: 'What to expect when you rent a boat with the<br/>Chicago Electric Boat Company',
      list: [
        {
          title: '',
          description: '',
        },
        {
          title: '',
          description: '',
        },
        {
          title: '',
          description: '',
        },
      ],
    },
    boats: {
      title: '',
      description: '',
    },
    corporate: {
      title: 'Corporate',
      description: '',
      redFooterText: 'Connect with People. Cruise the River. Our motto is pretty straightforward.',
      email: '',
    },
    faq: {
      title: 'POLICIES & FAQ',
      description: 'You’ve got questions, we’ve got answers!<br/>Don’t see what you’re looking for? Give us a <a href="tel:+13126446411">call</a> or shoot us an <a href="mailto:rentme@chicagoelectricboats.com ">email</a> through our contact page.',
    },
    contactUs: {
      title: 'Contact Us',
    },
  };

  const [data, setData] = useState({
    home,
    rentAYacht,
    rentABoats,
  });

  return {
    appContent: data,
    setAppContent: setData,
  };
};

export default AppContent;
