import {useEffect} from 'react';

const useExternalScript = ({url, body, inHead = false}) => {
  useEffect(() => {
    const documentHead = document.querySelector('head');
    const documentBody = document.querySelector('body');
    const script = document.createElement('script');

    script.setAttribute('async', 'true');

    if (url) {
      script.setAttribute('src', url);
    }

    if (body) {
      script.innerHTML = body;
    }

    if (inHead) {
      documentHead.appendChild(script);
    } else {
      documentBody.appendChild(script);
    }

    return () => {
      documentHead.removeChild(script);
      documentBody.removeChild(script);
    };
  }, [url]);
};

export default useExternalScript;
