import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSadTear} from '@fortawesome/free-solid-svg-icons';
import style from './notFound.module.scss';

export default function NotFound() {
  return (
    <div className={style.main}>
      <FontAwesomeIcon icon={faSadTear} />
      <h1>Not Found</h1>
      <p>Page not found</p>
    </div>
  );
}
