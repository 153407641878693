import React from 'react';
import {useHistory} from 'react-router-dom';
import logo from './logo.png';
import style from './logo.module.scss';

function Logo() {
  const history = useHistory();
  const handleClick = () => {
    history.push('/');
  };
  return (
    <div className={style.logo} onClick={handleClick}>
      <img className={style.image} src={logo} alt="Chicago Electric Boat Company" title="Chicago Electric Boat Company" />
      <span className={style.text}>
        Chicago Electric <br /> Boat Company
      </span>
    </div>
  );
}

export default Logo;
