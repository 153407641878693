import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import {hydrate, render} from 'react-dom';
import './index.css';
import MenuProvider from 'react-flexible-sliding-menu';
import TagManager from 'react-gtm-module';
import Menu from 'components/common/menu';
import {StoreContainer} from 'utils/store';
import CustomerSurvey from 'pages/customerSurvey';
import {BrowserRouter as Router} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';

const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

if (gtmId) {
  TagManager.initialize({
    gtmId,
  });
}

const app = (
  <StoreContainer.Provider>
    <Router>
      <CustomerSurvey />
      <MenuProvider MenuComponent={Menu} direction="right">
        <App />
      </MenuProvider>
    </Router>
  </StoreContainer.Provider>
);

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
