import {useState, useEffect} from 'react';
import {createContainer} from 'unstated-next';
import {useFetch, APIMethod} from 'utils/getData';
import {usePersistState} from 'persist-state';
import axios from 'axios';
import AppContent from './appContent';

function useSelection() {
  const [oAuthAccessToken, setOAuthAccessToken] = useState(localStorage.getItem('oAuthAccessToken'));
  const [oAuthRefreshToken, setOAuthRefreshToken] = useState(localStorage.getItem('oAuthRefreshToken'));
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  const [userData, setUserData] = usePersistState(
    {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      address: '',
      address2: '',
      country: '',
      city: '',
      state: '',
      zip: '',
      can_receive_email: 1,
    },
    'userData',
  );
  const [cachedUserData, setCachedUserData] = useState(userData);

  const getProfileData = () => {
    axios
      .get(APIMethod.profile.getUserData, {
        headers: {
          Authorization: `Bearer ${oAuthAccessToken}`,
        },
      })
      .then((response) => {
        setUserData((prevState) => ({
          ...prevState,
          ...response.data,
        }));
        setCachedUserData((prevState) => ({
          ...prevState,
          ...response.data,
        }));
        setIsUserAuthorized(true);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          // eslint-disable-next-line no-use-before-define
          refreshOAuthTokens();
          return false;
        }
        // eslint-disable-next-line no-console
        console.warn(error);
      });
  };

  const refreshOAuthTokens = () => {
    if (oAuthRefreshToken === null || oAuthRefreshToken === undefined || oAuthRefreshToken === 'null' || oAuthRefreshToken === 'undefined') return false;
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlEncoded = new URLSearchParams();
    urlEncoded.append('grant_type', 'refresh_token');
    urlEncoded.append('refresh_token', oAuthRefreshToken);
    urlEncoded.append('client_id', 'cebclient');
    urlEncoded.append('client_secret', 'cebpass');

    const requestOptions = {
      method: 'POST',
      headers,
      body: urlEncoded,
      redirect: 'follow',
    };

    fetch(APIMethod.profile.refreshTokens, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 400) {
          // eslint-disable-next-line no-console
          console.warn(data);
          return;
        }
        if (data.status === 200) {
          setOAuthAccessToken(data.access_token);
          setOAuthRefreshToken(data.refresh_token);
          getProfileData();
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn(error);
      });
  };

  useEffect(() => {
    if (oAuthRefreshToken !== null && oAuthRefreshToken !== undefined && oAuthRefreshToken !== 'null' && oAuthRefreshToken !== 'undefined' && oAuthAccessToken !== null && oAuthAccessToken !== undefined && oAuthAccessToken !== 'null' && oAuthAccessToken !== 'undefined') {
      localStorage.setItem('oAuthAccessToken', oAuthAccessToken);
      localStorage.setItem('oAuthRefreshToken', oAuthRefreshToken);
      getProfileData();
    }
  }, [oAuthAccessToken, oAuthRefreshToken]);

  const [location, setLocation] = usePersistState(null, 'location');
  const [cruiseLength, setCruiseLength] = usePersistState(null, 'cruiseLength');
  const [availableDays, setAvailableDays] = usePersistState(null, 'availableDays');
  const [promocode, setPromocode] = usePersistState(null, 'promocode');

  const [selectedBoat, setSelectedBoat] = usePersistState(null, 'selectedBoat');
  const [selectedTime, setSelectedTime] = usePersistState(null, 'selectedTime');
  const [selectedDay, setSelectedDay] = usePersistState(null, 'selectedDay');
  const [boatGroups, setBoatGroups] = usePersistState(null, 'boatGroups');
  const [availableHours, setAvailableHours] = usePersistState(null, 'availableHours');
  const [mapPopupHidden, setMapPopupHidden] = useState(false);

  const [month, setMonth] = usePersistState(null, 'month');
  const [googleMapKey, setGoogleMapKey] = useState(null);
  const [stripePublicKey, setStripePublicKey] = usePersistState(null, 'stripePublicKey');

  const [boatPass, setBoatPass] = useState(null);
  const [selectedBoatPass, setSelectedBoatPass] = useState(null);

  const getSettings = useFetch(APIMethod.settings);
  const _getMaxRentalTime = () => {
    if (!selectedBoat?.quartersMap) return [[], true];

    const cruiseLengthData = Object.entries(selectedBoat.quartersMap).map(([key, value]) => ({
      name: value,
      rentalTime: key,
    }));

    return [cruiseLengthData, false];
  };

  const getStripeKey = () => {
    axios
      .get(APIMethod.settings)
      .then((response) => {
        setStripePublicKey(response.data.stripePublicKey);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.warn(e);
      });
  };

  const getKeys = () => {
    axios
      .get(APIMethod.settings)
      .then((response) => {
        setStripePublicKey(response.data.stripePublicKey);
        setGoogleMapKey(response.data.googleMapsApiKey);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.warn(e);
      });
  };

  const getBoatPass = () => {
    axios
      .get(APIMethod.boatPass)
      .then((response) => {
        setBoatPass(response.data);
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.warn(e));
  };

  const getBoatLocation = useFetch(APIMethod.boatLocation);
  const getCruiseLength = _getMaxRentalTime();

  const {appContent, setAppContent} = AppContent();
  const getRentAYacht = () => {
    const getDescription = () => {
      axios.get(`${APIMethod.getAppContent}?key=rent_a_yacht_cruise_description`).then((response) => {
        const {status, data} = response;
        if (status === 200) {
          const {rentAYacht} = appContent;
          rentAYacht.content.description = data?.content;
          setAppContent({
            ...appContent,
            rentAYacht,
          });
        }
      });
    };
    const getGallery = () => {
      axios.get(`${APIMethod.getAppContent}?key=rent_a_yacht_gallery`).then((response) => {
        const {status, data} = response;
        if (status === 200 && data) {
          const {rentAYacht} = appContent;
          rentAYacht.gallery = data?.image_urls;
          setAppContent({
            ...appContent,
            rentAYacht,
          });
        }
      });
    };
    const getAbout = () => {
      axios.get(`${APIMethod.getAppContent}?key=rent_a_yacht_about`).then((response) => {
        const {status, data} = response;
        if (status === 200 && data) {
          const {rentAYacht} = appContent;
          rentAYacht.yachts.title = data?.title;
          rentAYacht.yachts.description = data?.content;
          setAppContent({
            ...appContent,
            rentAYacht,
          });
        }
      });
    };

    getDescription();
    getGallery();
    getAbout();
  };
  const getRentABoat = () => {
    const getPrice = () => {
      axios.get(`${APIMethod.getAppContent}?key=rent_a_boat_prices`).then((response) => {
        const {status, data} = response;
        if (status === 200) {
          const {rentABoats} = appContent;
          rentABoats.prices.description = data?.content;
          rentABoats.prices.title = data?.title;
          rentABoats.prices.image = data?.image_url;
          setAppContent({
            ...appContent,
            rentABoats,
          });
        }
      });
    };
    const getAbout = () => {
      axios.get(`${APIMethod.getAppContent}?key=rent_a_boat_boats`).then((response) => {
        const {status, data} = response;
        if (status === 200 && data) {
          const {rentABoats} = appContent;
          rentABoats.boats.title = data?.title;
          rentABoats.boats.description = data?.content;
          setAppContent({
            ...appContent,
            rentABoats,
          });
        }
      });
    };
    const getCruise = () => {
      axios.get(`${APIMethod.getAppContent}?key=rent_a_boat_what_to_expect`).then((response) => {
        const {status, data} = response;
        if (status === 200 && data) {
          const {rentABoats} = appContent;
          rentABoats.cruise.list[0].title = data?.title1;
          rentABoats.cruise.list[0].description = data?.content1;
          rentABoats.cruise.list[1].title = data?.title2;
          rentABoats.cruise.list[1].description = data?.content2;
          rentABoats.cruise.list[2].title = data?.title3;
          rentABoats.cruise.list[2].description = data?.content3;
          setAppContent({
            ...appContent,
            rentABoats,
          });
        }
      });
    };
    const getCorporate = () => {
      axios.get(`${APIMethod.getAppContent}?key=rent_a_boat_corporate`).then((response) => {
        const {status, data} = response;
        if (status === 200 && data) {
          const {rentABoats} = appContent;
          rentABoats.corporate.title = data?.title;
          rentABoats.corporate.description = data?.content;
          rentABoats.corporate.email = data?.contacts;
          rentABoats.corporate.redFooterText = data?.bottomText;
          setAppContent({
            ...appContent,
            rentABoats,
          });
        }
      });
    };

    getPrice();
    getAbout();
    getCruise();
    getCorporate();
  };
  const getHome = () => {
    const getBoatTypesList = () => {
      axios.get(`${APIMethod.getAppContent}?key=home_boat_types`).then((response) => {
        const {status, data} = response;
        if (status === 200 && data) {
          const {home} = appContent;
          const list = [data.content1, data.content2, data.content3, data.content4];
          home.boatTypes.list = list;
          setAppContent({
            ...appContent,
            home,
          });
        }
      });
    };
    getBoatTypesList();
  };
  const getAppContent = () => {
    getRentAYacht();
    getRentABoat();
    getHome();
  };
  useEffect(() => {
    getAppContent();
  }, []);

  return {
    location,
    setLocation,
    getBoatLocation,
    cruiseLength,
    setCruiseLength,
    getCruiseLength,
    availableDays,
    setAvailableDays,
    promocode,
    setPromocode,
    boatGroups,
    setBoatGroups,
    selectedBoat,
    setSelectedBoat,
    availableHours,
    setAvailableHours,
    selectedDay,
    setSelectedDay,
    selectedTime,
    setSelectedTime,
    getSettings,
    mapPopupHidden,
    setMapPopupHidden,
    stripePublicKey,
    getStripeKey,
    googleMapKey,
    getKeys,
    month,
    setMonth,
    boatPass,
    getBoatPass,
    selectedBoatPass,
    setSelectedBoatPass,
    oAuthAccessToken,
    setOAuthAccessToken,
    oAuthRefreshToken,
    setOAuthRefreshToken,
    refreshOAuthTokens,
    getProfileData,
    userData,
    setUserData,
    cachedUserData,
    setCachedUserData,
    isUserAuthorized,
    setIsUserAuthorized,
    appContent,
  };
}

export const StoreContainer = createContainer(useSelection);
