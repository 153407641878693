/* eslint-disable react-hooks/rules-of-hooks */
import {useState, useEffect} from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_BASE_URL;

const APIMethod = {
  reservation: {
    reservation: `${baseUrl}/reservation/reservation?format=json`,
    cost: `${baseUrl}/reservation/cost-calculate?format=json`,
    boatSchedule: `${baseUrl}/reservation/get-boat-schedule?format=json`,
  },
  boatLocation: `${baseUrl}/boat-location/index`,
  boatGroup: `${baseUrl}/boat-group-description/index?format=json`,
  addons: {
    interest: `${baseUrl}/additional/get-interests?format=json`,
    occasion: `${baseUrl}/additional/get-occasions?format=json`,
  },
  boat: `${baseUrl}/boat/capacity?format=json`,
  schedule: `${baseUrl}/schedule/get?format=json`,
  settings: `${baseUrl}/settings?format=json`,
  about: `${baseUrl}/about-us/get-items?format=json`,
  faq: {
    groups: `${baseUrl}/faq-group/get-groups?format=json`,
    faqs: `${baseUrl}/faq/get-faqs?format=json`,
  },
  subscribe: `${baseUrl}/subscribe/subscribe?format=json`,
  boatPass: `${baseUrl}/boat-pass-type/index?format=json`,
  insurance: `${baseUrl}/additional/get-insurances?format=json`,
  profile: {
    authorization: `${baseUrl}/oauth2/rest/token`,
    refreshTokens: `${baseUrl}/oauth2/rest/token`,
    getUserData: `${baseUrl}/customer/profile`,
    editUserData: `${baseUrl}/customer/edit-profile`,
    changePassword: `${baseUrl}/customer/change-password`,
    requestPasswordReset: `${baseUrl}/customer-auth/request-password-reset`,
    resetPassword: `${baseUrl}/customer-auth/reset-password`,
    getUserReservations: `${baseUrl}/customer-reservation/index`,
  },
  myReservations: {
    getReservation: `${baseUrl}/customer-reservation/view`,
    editReservation: `${baseUrl}/customer-reservation/update`,
    cancelReservation: `${baseUrl}/customer-reservation/cancel`,
  },
  getAppContent: `${baseUrl}/block/get-settings`,
};

function fetchData(url, successBlock, errorBlock) {
  axios
    .get(url)
    .then((response) => {
      successBlock(response);
    })
    .catch((error) => {
      errorBlock(error);
      // eslint-disable-next-line no-console
      console.log(error);
    });
}

function useFetch(url) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axiosRetry(axios, {retries: 3});
    axios
      .get(url)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Error:');
        // eslint-disable-next-line no-console
        console.error(error);
      });
    axiosRetry(axios, {retryDelay: axiosRetry.exponentialDelay});
  }, [url]);

  return [data, isLoading];
}

function fetchFirst(url) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      const result = await axios(url);
      setData(result.data[0]);
      setIsLoading(false);
    }
    getData();
  }, []);

  return [data, isLoading];
}

export {useFetch, fetchFirst, fetchData, baseUrl, APIMethod};
