import React, {useContext, useEffect} from 'react';
import './App.scss';
import {Switch, Route, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {MenuContext} from 'react-flexible-sliding-menu';
import {typeApp, typeCopy} from 'utils/constants';
import NotFound from 'pages/notFound';
import loadable from '@loadable/component';
import Header from './components/common/header';
import useExternalScript from './utils/useExternalScript';

const GOOGLE_AW_ID = process.env.REACT_APP_GOOGLE_AW_ID;

const fallbackStyle = {
  marginTop: '0',
  paddingTop: '200px',
  textAlign: 'center',
};

const ReservationBlock = loadable(() => import('pages/reservation/reservationBlock'), {
  fallback: <h1 style={fallbackStyle}>Preparing your reservation...</h1>,
});
const BoatPassBlock = loadable(() => import('pages/boatPass'), {
  fallback: <h1 style={fallbackStyle}>Preparation of boat passes...</h1>,
});
const BuyBoatPassBlock = loadable(() => import('pages/buyBoatPass/boatPassBlock'), {
  fallback: <h1 style={fallbackStyle}>Preparing to purchase...</h1>,
});
const HomeLandingBlock = loadable(() => import('pages/homeLanding'), {
  fallback: <h1 style={fallbackStyle}>Preparing data...</h1>,
});
const RentABoat = loadable(() => import('pages/rentABoat'), {
  fallback: <h1 style={fallbackStyle}>Preparing data...</h1>,
});
const RentAYacht = loadable(() => import('pages/rentAYacht'), {
  fallback: <h1 style={fallbackStyle}>Preparing data...</h1>,
});
const ProfileBlock = loadable(() => import('pages/profile'), {
  fallback: <h1 style={fallbackStyle}>Preparing data...</h1>,
});
const AuthorizationBlock = loadable(() => import('pages/authorization'), {
  fallback: <h1 style={fallbackStyle}>Preparing data...</h1>,
});
const PasswordRecoveryBlock = loadable(() => import('pages/passwordRecovery'), {
  fallback: <h1 style={fallbackStyle}>Preparing data...</h1>,
});
const MyReservationBlock = loadable(() => import('pages/myReservation'), {
  fallback: <h1 style={fallbackStyle}>Preparing data...</h1>,
});
const ChangePasswordBlock = loadable(() => import('pages/changePassword'), {
  fallback: <h1 style={fallbackStyle}>Preparing data...</h1>,
});
const PrivacyPolicyBlock = loadable(() => import('pages/privacyPolicy'), {
  fallback: <h1 style={fallbackStyle}>Preparing data...</h1>,
});

const AppOriginal = ({toggleMenu, isClient}) => (
  <div className="App">
    {isClient ? <Header toggleMenu={toggleMenu} /> : null}
    <Switch>
      <Route exact path="/">
        <HomeLandingBlock />
      </Route>
      <Route path="/rent-a-boat">
        <RentABoat />
      </Route>
      <Route path="/rent-a-yacht">
        <RentAYacht />
      </Route>
      <Route path="/reservation">
        <ReservationBlock />
      </Route>
      <Route path="/customers-survey" />
      <Route path="/boatpass">
        <BoatPassBlock />
      </Route>
      <Route path="/buy-boatpass">
        <BuyBoatPassBlock />
      </Route>
      <Route path="/profile">
        <ProfileBlock />
      </Route>
      <Route path="/authorization">
        <AuthorizationBlock />
      </Route>
      <Route path="/my-reservation">
        <MyReservationBlock />
      </Route>
      <Route path="/password-recovery">
        <PasswordRecoveryBlock />
      </Route>
      <Route path="/customer-auth/reset-password">
        <ChangePasswordBlock />
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicyBlock />
      </Route>
      <Route path="*" component={NotFound} />
    </Switch>
  </div>
);

AppOriginal.propTypes = {
  toggleMenu: PropTypes.func,
  isClient: PropTypes.bool,
};

const AppCopy = ({toggleMenu, isClient}) => (
  <div className="App">
    {isClient ? <Header toggleMenu={toggleMenu} /> : null}
    <Switch>
      <Route exact path="/">
        <HomeLandingBlock />
      </Route>
      <Route path="/rent-a-boat">
        <RentABoat />
      </Route>
      <Route path="/reservation">
        <ReservationBlock />
      </Route>
      <Route path="/customers-survey" />
      <Route path="/boatpass">
        <BoatPassBlock />
      </Route>
      <Route path="/buy-boatpass">
        <BuyBoatPassBlock />
      </Route>
      <Route path="/profile">
        <ProfileBlock />
      </Route>
      <Route path="/authorization">
        <AuthorizationBlock />
      </Route>
      <Route path="/my-reservation">
        <MyReservationBlock />
      </Route>
      <Route path="/password-recovery">
        <PasswordRecoveryBlock />
      </Route>
      <Route path="/customer-auth/reset-password">
        <ChangePasswordBlock />
      </Route>
      <Route path="*" component={NotFound} />
    </Switch>
  </div>
);

AppCopy.propTypes = {
  toggleMenu: PropTypes.func,
  isClient: PropTypes.bool,
};

function App() {
  if (!process) return null;
  const location = useLocation();

  useEffect(() => {
    if (!window.gsc) return;

    window.gsc('trackPage', location.pathname);
  }, [location]);

  if (GOOGLE_AW_ID) {
    useExternalScript({
      url: `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_AW_ID}`,
      inHead: true,
    });
    useExternalScript({
      body: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${GOOGLE_AW_ID}');`,
      inHead: true,
    });
  }

  const type = process.env.REACT_APP_PROJECT_TYPE;
  const {toggleMenu} = useContext(MenuContext);
  const isClient = navigator.userAgent !== 'ReactSnap';

  if (type === typeApp) {
    return <AppOriginal toggleMenu={toggleMenu} isClient={isClient} />;
  } else if (type === typeCopy) {
    return <AppCopy toggleMenu={toggleMenu} isClient={isClient} />;
  }
}

export default App;
