import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {StoreContainer} from '../../../utils/store';
import style from './singInButton.module.scss';

const LoginButton = ({isLink, className}) => {
  const {getProfileData, isUserAuthorized, cachedUserData} = StoreContainer.useContainer();
  useEffect(() => {
    if (isUserAuthorized) {
      getProfileData();
    }
  }, [isUserAuthorized]);

  const history = useHistory();
  const signInButtonClickHandle = () => {
    if (isLink) {
      window.location.pathname = '/authorization';
      return;
    }
    history.push('/authorization');
  };
  const profileButtonClickHandle = () => {
    if (isLink) {
      window.location.pathname = '/profile';
      return;
    }
    history.push('/profile');
  };

  return (
    <div className={`${style.authorizationButtonWrapper} ${className}`}>
      {!isUserAuthorized ? (
        <div onClick={signInButtonClickHandle} className={style.authorizationButton}>
          Sign in
        </div>
      ) : (
        <div onClick={profileButtonClickHandle} className={style.profileButton}>
          <div className={style.profileButtonIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path fill="#ffffff" d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" />
            </svg>
          </div>
          {cachedUserData.first_name}
        </div>
      )}
    </div>
  );
};

LoginButton.propTypes = {
  isLink: PropTypes.bool,
  className: PropTypes.string,
};

export default LoginButton;
