import React from 'react';

function siteControlWidged() {
  const GET_SITE_CONTROL_ID = process.env.REACT_APP_GET_SITE_CONTROL_ID;

  if (GET_SITE_CONTROL_ID) {
    return (
      <script>
        {setTimeout(() => {
          // eslint-disable-next-line func-names
          (function (w, i, d, g, e, t, s) {
            w[d] = w[d] || [];
            t = i.createElement(g);
            t.async = 1;
            t.src = e;
            s = i.getElementsByTagName(g)[0];
            s.parentNode.insertBefore(t, s);
          })(window, document, '_gscq', 'script', `//l.getsitecontrol.com/${process.env.REACT_APP_GET_SITE_CONTROL_ID}.js`);
        }, 1000)}
      </script>
    );
  } else {
    return null;
  }
}

export const SiteControlWidged = React.memo(siteControlWidged);
